import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Carousel from "../components/UI/image-carousel"
import "../components/project-sections/project.css"

const ProjectTemplate = ({ data }) => {
  const { date, client, location, budget, title, image_list } =
    data.markdownRemark.frontmatter
  const body = data.markdownRemark.html

  return (
    <Layout>
      <Seo title={title} />
      <div className="container">
        <div className="project-page">
          <h2 style={{ fontSize: "2rem" }}>{title}</h2>
          <br />
          <h3>{client !== "N/A" ? `Client: ${client}` : null}</h3>
          <h3>{budget !== "N/A" ? `Budget: ${budget}` : null}</h3>
          <h3>{location !== "N/A" ? `Location: ${location}` : null}</h3>
          <h3>{date !== "N/A" ? `Completion: ${date}` : null}</h3>
          <br />
          <Carousel images={image_list} />
          <br />
          <div
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        budget
        category
        location
        video {
          publicURL
        }
        client
        image_list {
          image_1 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_2 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_3 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_4 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_5 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_6 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_7 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
          image_8 {
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: AUTO
                width: 800
              )
            }
          }
        }
      }
    }
  }
`

export default ProjectTemplate
